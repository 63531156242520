
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";
import {
  BIconClipboardDataFill,
  BIconCalendarFill,
  BIconPiggyBankFill,
  BIconArchiveFill,
  BIconPersonHearts,
  BIconCalendarEventFill,
  BIconPeopleFill,
  BIconGearFill,
} from "bootstrap-icons-vue";
import { CSidebar } from "@coreui/vue";

export default defineComponent({
  name: "AppSidebar",
  components: {
    InlineSvg,
    BIconClipboardDataFill,
    BIconCalendarFill,
    BIconPiggyBankFill,
    BIconArchiveFill,
    BIconPersonHearts,
    BIconCalendarEventFill,
    BIconPeopleFill,
    BIconGearFill,
    CSidebar,
  },
  computed: {
    sidebarUnfoldable() {
      return this.$store.state.theme.sidebarUnfoldable;
    },
    sidebarVisible() {
      return this.$store.state.theme.sidebarVisible;
    },
  },
});
