
import { defineComponent } from "vue";
import { CDropdown, CDropdownToggle, CDropdownMenu } from "@coreui/vue";
import { BIconShieldLockFill, BIconLockFill } from "bootstrap-icons-vue";

export default defineComponent({
  name: "AppAccount",
  components: {
    BIconShieldLockFill,
    BIconLockFill,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
  },
  methods: {
    logout() {
      this.$store.commit("auth/logout");
      this.$store.commit("toast/add", {
        type: "success",
        message: "Pomyślnie wylogowano z Panelu Administratora.",
      });
      this.$router.push({ name: "Login" });
    },
  },
});
